import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from './pages/HomePage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import ContactPage from './pages/ContactPage';
import LocationPage from "./pages/LocationPage";
import ScrollToTop from "./pages/ScrollToTop";
import ShipchajangMap from "./pages/parkingLotLayout";

function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/terms" element={<TermsPage/>} />
        <Route path="/privacy" element={<PrivacyPage/>} />
        <Route path="/contact" element={<ContactPage/>} />
        <Route path="/location" element={<LocationPage/>} />
        <Route path="/seat" element= {<ShipchajangMap/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
